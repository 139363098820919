<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Editar proposta</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Nome *"
                  v-model="editedDps.name"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="Sobrenome *"
                  v-model="editedDps.lastName"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="CPF *"
                  v-model="editedDps.cpf"
                  :rules="[rules.required, rules.cpf]"
                  required
                  v-mask="'###.###.###-##'"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="editedDps.sex"
                  :items="['Masculino', 'Feminino']"
                  :rules="[rules.required]"
                  label="Gênero *"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedDps.birthDate"
                      label="Data de Nascimento *"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedDps.birthDate"
                    no-title
                    @input="setDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  label="E-mail *"
                  v-model="editedDps.email"
                  :rules="[rules.required, rules.email]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  label="Celular *"
                  v-model="editedDps.phone"
                  :rules="[rules.required]"
                  required
                  v-mask="['(##) ####-####', '(##) #####-####']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>*campos obrigatórios</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close()">
          Fechar
        </v-btn>
        <v-btn color="green darken-1" text @click="validateForm()">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <confirmModal ref="confirmModal"></confirmModal>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import rules from "@/utils/rules";
import { post } from "@/utils/api";
import { assign, isEqual } from "lodash";
import { updateDps } from "@/graphql/mutations";
import { API } from "aws-amplify";

export default {
  name: "EditModal",
  props: ["item", "toggle"],
  components: {
    ConfirmModal: () => import("@/components/ConfirmModal.vue"),
  },
  data() {
    return {
      rules: rules,
      datePicker: false,
      valid: false,
      editedDps: {
        id: "",
        name: "",
        lastName: "",
        sex: "",
        email: "",
        cpf: "",
        birthDate: ""
      }
    };
  },
  mounted() {
    assign(this.editedDps, this.item);
  },
  watch: {
    item(item) {
      assign(this.editedDps, item);
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  methods: {
    close() {
      this.toggle();
    },
    setDate(date) {
      this.date = this.parseDate(date);

      this.datePicker = false;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.toString().split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    async validateForm() {
      if (this.$refs.form.validate()) {
        this.editedDps.phone = this.editedDps.phone.replace(/\D/g, "");

        if (isEqual(this.editedDps, this.item))
          return this.showAlert("error", "Nenhuma informação da proposta foi alterada");

        this.$refs.confirmModal
          .open(
            'Atenção',
            `Você deseja realmente realizar esta edição?
             o cliente receberá uma nova proposta para assinatura`,
            { color: "#ff9800" }
          ).then(confirmed => {
            if (confirmed) this.updateDpsAndSendEmail();
            else this.close();
          });
      }
    },
    async updateDpsAndSendEmail() {
      try {
        this.$store.commit("loading", true);
        const res = await post({ path: "/dps/update", body: this.editedDps });
        if (res.document && res.document_key) {
          this.editedDps.documentKey = res.document_key;
          this.editedDps.signedDate = null;
          this.editedDps.editedName = this.$store.state.user.name;
          this.editedDps.updatedAt = new Date().toISOString();
          await API.graphql({
            query: updateDps,
            variables: {
              input: this.editedDps
            }
          });
        }
        assign(this.item, this.editedDps);
        this.showAlert("success", "Proposta atualizada com sucesso");
        this.close();
      } catch (error) {
        assign(this.editedDps, this.item);
        this.showAlert("error", "Erro ao processar solicitação");
      } finally {
        this.$store.commit("loading", false);
      }
    },
    showAlert(type, message) {
      this.$store.commit("showAlert", { type, message });
    }
  }
};
</script>
